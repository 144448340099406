import { useEffect, useState } from 'react';

export const useRoomReconnection = room => {
  const [reconnecting, setReconnecting] = useState(false);
  const [reconnected, setReconnected] = useState(false);

  const onReconnecting = () => {
    setReconnecting(true);
    setReconnected(false);
  };

  const onReconnect = () => {
    setReconnected(true);
    setReconnecting(false);
  };

  useEffect(() => {
    if (room) {
      room.on('signalReconnecting', onReconnecting);
      room.on('reconnecting', onReconnecting);
      room.on('reconnected', onReconnect);
    }
    return () => {
      if (room) {
        room.off('signalReconnecting', onReconnecting);
        room.off('reconnecting', onReconnecting);
        room.off('reconnected', onReconnect);
      }
    };
  }, [room]);

  return { reconnected, reconnecting };
};

export default useRoomReconnection;
